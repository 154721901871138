import { Skeleton, Card } from "antd";

const ProductSkeleton = () => {
  return (
    <Card
      hoverable
      className="rounded-none grow-1 grow shrink-0"
      cover={
        <div className="w-full h-[254px] bg-[#F9F9F9]">
          <Skeleton.Avatar
            size={253}
            className="w-full h-full"
            shape="square"
            active
          />
        </div>
      }
    >
      <div className="mt-[15px]">
        <div className="flex flex-col gap-[15px]">
          <div className="text-[24px] font-bold  transition ease-in-out">
            <Skeleton.Input active />
          </div>

          <div className="flex flex-col gap-[7.5px]">
            <div className="text-[18px]">
              <Skeleton active paragraph={{ rows: 1 }} />
            </div>
            <div className="text-[11px] text-[#B9B9B9]">
              <Skeleton active paragraph={{ rows: 1 }} />
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default ProductSkeleton;
