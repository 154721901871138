import React from "react"
import FormItem from "../../components/general/form-item/FormItem"
import { Button, Input } from "antd"
import { useNavigate } from "react-router-dom"
import { Controller, useForm } from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message"

const Register = () => {
  const navigate = useNavigate()

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm()

  const onSubmit = async (data: any) => {
    navigate("address", {
      state: { data: data },
    })
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      dir="rtl"
      className="flex flex-col w-[100%] md:w-[80%] lg:w-[50%] h-full justify-between items-center mt-[100px]"
    >
      <div className="flex md:flex-row flex-col w-full justify-between items-center">
        <div className="flex flex-col gap-[30px]">
          <div className="text-[40px] font-black">
            مرحباً بك في <span className="text-[#F36B10]">آفاق</span>
          </div>
          <div className="flex  gap-[15px] items-center">
            <div className="h-[5px] w-[120px] rounded-md bg-[#A8A8A8]"></div>

            <div className="h-[5px] w-[120px] rounded-md bg-[#F36B10]"></div>
          </div>
        </div>
        <div>
          <img
            className="cursor-pointer w-[300px] object-cover md:-me-[80px] h-[200px]"
            onClick={() => navigate("/")}
            src="/images/logo.png"
            alt=""
          />
        </div>
      </div>

      <div className="flex w-full flex-col gap-[40px] mt-[50px]">
        <FormItem label="الاسم كامل">
          <Controller
            control={control}
            rules={{
              required: { value: true, message: "الاسم الكامل مطلوب" },
            }}
            name="name"
            render={({ field }) => {
              return (
                <Input
                  {...field}
                  placeholder="الاسم الكامل"
                  className="h-[70px] rounded-none text-[15px] font-black"
                />
              )
            }}
          />
          <ErrorMessage
            className="mt-4 text-red-600"
            as={"div"}
            errors={errors}
            name="name"
          />
        </FormItem>

        <FormItem label="رقم الهاتف">
          <Controller
            control={control}
            rules={{
              required: { value: true, message: "رقم الهاتف مطلوب" },
            }}
            name="phone"
            render={({ field }) => {
              return (
                <Input
                  {...field}
                  placeholder="رقم الهاتف"
                  className="h-[70px] rounded-none text-[15px] font-black"
                />
              )
            }}
          />
          <ErrorMessage
            className="mt-4 text-red-600"
            as={"div"}
            errors={errors}
            name="phone"
          />
        </FormItem>

        <FormItem label="البريد الالكتروني">
          <Controller
            control={control}
            rules={{
              required: { value: true, message: "رقم الهاتف مطلوب" },
            }}
            name="email"
            render={({ field }) => {
              return (
                <Input
                  {...field}
                  type="email"
                  placeholder="البريد الالكتروني"
                  className="h-[70px] rounded-none text-[15px] font-black"
                />
              )
            }}
          />
          <ErrorMessage
            className="mt-4 text-red-600"
            as={"div"}
            errors={errors}
            name="email"
          />
        </FormItem>

        <FormItem label="كلمة المرور">
          <Controller
            control={control}
            rules={{
              required: { value: true, message: "كلمة المرور مطلوبة" },
            }}
            name="password"
            render={({ field }) => {
              return (
                <Input.Password
                  {...field}
                  className="h-[70px] rounded-none text-[15px] font-black"
                  placeholder="كلمة المرور"
                />
              )
            }}
          />
          <ErrorMessage
            className="mt-4 text-red-600"
            as={"div"}
            errors={errors}
            name="password"
          />
        </FormItem>

        <Controller
          control={control}
          rules={{
            required: { value: true, message: "تأكيد كلمة المرور مطلوبة" },
            validate: (value) => {
              return (
                value === getValues("password") || `كلمتا المرور غير متطابقتان`
              )
            },
          }}
          name="confirmPassword"
          render={({ field }) => {
            return (
              <Input.Password
                {...field}
                className="h-[70px] rounded-none text-[15px] font-black"
                placeholder="كلمة المرور"
              />
            )
          }}
        />
        <ErrorMessage
          className="mt-4 text-red-600"
          as={"div"}
          errors={errors}
          name="confirmPassword"
        />
      </div>

      <div className="w-full mt-[40px] flex justify-between gap-[40px]">
        <Button
          className="w-1/2 h-[74px] text-[20px] font-black"
          type="primary"
          htmlType="submit"
        >
          اتمام التسجيل
        </Button>
        <Button
          className="w-1/2 text-[#F36B10] text-[20px] font-black h-[74px]"
          type="default"
          onClick={() => navigate("/login")}
        >
          تسجيل الدخول
        </Button>
      </div>
    </form>
  )
}

export default Register
