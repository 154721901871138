import { useContext } from "react";
import ProductGrid from "../../productGrid.tsx/ProductGrid";
import { Link } from "react-router-dom";
import HomeContext from "../../../../context.tsx/home/context";

const MostSellingSection = () => {
  const { data, loading } = useContext(HomeContext);

  return (
    <>
      <div className="flex justify-between items-center ">
        <div className="text-[30px] font-bold">الأكثر مبيعاً</div>
        <Link
          to={"/most-selling"}
          className="text-black hover:underline hover:text-[#F36B10] transition ease-in-out text-[24px] "
          // style={{ color: "black" }}
          color="black"
        >
          عرض الكل
        </Link>
      </div>

      <ProductGrid products={data?.bestSeller ?? []} loading={loading} />
    </>
  );
};

export default MostSellingSection;
