import React from "react";

const UsagePolicy = () => {
  return (
    <div className="mt-4 flex flex-col gap-[60px]">
      <div className="flex flex-col gap-[40px]">
        <div className="text-[30px] font-bold text-[#F36B10]">
          الشروط والاحكام
        </div>
        <div className="text-[20px] leading-[32px]">
          هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي
          القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة
          التي يقرأها. هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء
          لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع
          الفقرات في الصفحة التي يقرأها. هناك حقيقة مثبتة منذ زمن طويل وهي أن
          المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي
          للنص أو شكل توضع الفقرات في الصفحة التي يقرأها.{" "}
        </div>
      </div>

      <div className="flex flex-col gap-[40px]">
        <div className="text-[30px] font-bold text-[#F36B10]">
          معلومات عن التطبيق
        </div>
        <div className="text-[20px] leading-[32px]">
          هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي
          القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة
          التي يقرأها. هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء
          لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع
          الفقرات في الصفحة التي يقرأها. هناك حقيقة مثبتة منذ زمن طويل وهي أن
          المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي
          للنص أو شكل توضع الفقرات في الصفحة التي يقرأها.{" "}
        </div>
      </div>
    </div>
  );
};

export default UsagePolicy;
