import ImageCover from "../../components/home/image-cover/ImageCover";
import CategoryContainer from "../../components/home/category-container/CategoryContainer";
import Container from "../../components/general/container/Container";
import MostSellingSection from "../../components/general/sections/most-selling/MostSellingSection";
import LatestOfferSection from "../../components/general/sections/latest-offers/LatestOffersSection";
import { useContext, useEffect, useState } from "react";
import http from "../../api/api";
import HomeContext from "../../context.tsx/home/context";

const Home = () => {
  const { data, loading } = useContext(HomeContext);

  const [images, setImages] = useState<string[]>([]);

  useEffect(() => {
    const getImages = async () => {
      try {
        const { data } = await http.get("/slider");
        setImages(data.data.map((item: any) => item.cover));
      } catch (err) {
        console.log(err);
      }
    };
    if (images.length === 0) {
      getImages();
    }
  }, [images.length]);

  return (
    <>
      <ImageCover images={images} />
      <CategoryContainer
        categories={data?.categories ?? []}
        loading={loading}
        navigateUrl="/category"
      />
      <Container>
        <MostSellingSection />
        <div className="mt-[70px]">
          <img
            className="w-full"
            src="/images/chair-cover.png"
            alt="chair cover"
          />
        </div>
        <div className="mt-[96px]">
          <LatestOfferSection />
        </div>
      </Container>
    </>
  );
};

export default Home;
