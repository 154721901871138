import { Button, Input } from "antd";
import Container from "../../components/general/container/Container";
import FormItem from "../../components/general/form-item/FormItem";
import { useNavigate } from "react-router-dom";

const ConfirmAddress = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <div className="flex flex-col gap-[17px] ">
        <div className="text-[40px] font-black">تأكيد العنوان</div>
        <div className="w-[240px] leading-[24px] text-center text-[#7A7A7A] text-[18px]">
          يجب عليك ادخال البينات الصحيح لتجنب المشاكل والاخطاء
        </div>

        <div className="mt-[40px] flex flex-col gap-[40px]">
          <FormItem label="المحافظة">
            <Input
              className="h-[60px] text-[24px] font-semibold rounded-none"
              placeholder="المحافظة"
            />
          </FormItem>

          <FormItem label="المنطقة">
            <Input
              className="h-[60px] text-[24px] font-semibold rounded-none"
              placeholder="المنطقة"
            />
          </FormItem>

          <FormItem label="عنوان المنزل">
            <Input
              className="h-[60px] text-[24px] font-semibold rounded-none"
              placeholder="عنوان المنزل"
            />
          </FormItem>
          <div className="flex justify-center">
            <Button
              onClick={() => navigate("/successful-purchase")}
              type="primary"
              className="w-full text-[20px] font-black h-[60px] md:w-[50%]"
            >
              متابعة الشراء
            </Button>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ConfirmAddress;
