import { Outlet } from "react-router-dom";
import Footer from "../main-layout/footer/Footer";

const RegistrationLayout = () => {
  return (
    <div className="h-full overflow-auto w-full flex flex-col justify-between">
      <div className="w-full h-full flex flex-col justify-center items-center">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default RegistrationLayout;
