import axios from "axios";

export const ACCESS_TOKEN = "access_token";

const http = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
  },
});

http.interceptors.request.use(
  (config: any) => {
    config.headers["Authorization"] = `Bearer ${localStorage.getItem(
      ACCESS_TOKEN
    )}`;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default http;
