import { useEffect, useState } from "react";
import Container from "../../components/general/container/Container";
import { Breadcrumb } from "antd";
import ProductGrid from "../../components/general/productGrid.tsx/ProductGrid";
import { IProduct } from "../../models/product";
import http from "../../api/api";
import { Link } from "react-router-dom";

const LatestOffers = () => {
  const [products, setProducts] = useState<IProduct[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await http.get("/products/offers");
        setProducts(data.data.map((item: any) => item.product));
      } catch (err) {
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Container>
        <Breadcrumb
          separator={<div className="text-[24px]">{`>`}</div>}
          items={[
            {
              title: (
                <Link className="text-[24px] p-[24px] hover:h-auto" to={"/"}>
                  الرئيسية
                </Link>
              ),
            },
            {
              title: "أحدث العروض",
              className: "text-[24px]",
            },
          ]}
        />
        <div className="mt-[70px]">
          <ProductGrid loading={loading} products={products} />
        </div>
      </Container>
    </>
  );
};

export default LatestOffers;
