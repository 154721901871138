import { Layout } from "antd";
import Navbar from "./navbar/Navbar";
import Footer from "./footer/Footer";
import { Outlet } from "react-router-dom";

const { Content } = Layout;
const MainLayout = () => {
  return (
    <Layout className="flex flex-col min-h-screen">
      <Navbar />
      <img
        className="fixed bottom-4 z-10 md:mr-[10px] opacity-50 hover:opacity-100 transition duration-200 ease-in-out cursor-pointer"
        src="/images/whatsapp.png"
        alt=""
      />
      <Content style={{ padding: "0px", margin: "0px", flex: 1 }}>
        <Outlet />
      </Content>
      <Footer />
    </Layout>
  );
};

export default MainLayout;
