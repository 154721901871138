import { useEffect, useState } from "react";
import Container from "../../components/general/container/Container";
import { Breadcrumb } from "antd";
import CategoryContainer from "../../components/home/category-container/CategoryContainer";
import ProductGrid from "../../components/general/productGrid.tsx/ProductGrid";
import { Link, useLocation, useParams } from "react-router-dom";
import { IProduct } from "../../models/product";
import { ICategory } from "../../models/category";
import http from "../../api/api";

const Category = () => {
  const { category } = useParams();

  const [categoryProducts, setCategoryProducts] = useState<IProduct[]>([]);
  const [loading, setLoading] = useState(true);
  const [categorySubs, setCategorySubs] = useState<ICategory[]>([]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        const subData = await http.get(`/category/${category}/subCat`);
        const { data } = await http.get(`/category/${category}/products`);

        setCategoryProducts(data.data);
        setCategorySubs(subData.data.data);
      } catch (err) {
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, [category]);

  const { state } = useLocation();
  const { name } = state;
  return (
    <>
      <Container>
        <Breadcrumb
          separator={<div className="text-[24px]">{`>`}</div>}
          items={[
            {
              title: (
                <Link className="text-[24px] p-[24px] hover:h-auto" to={"/"}>
                  الرئيسية
                </Link>
              ),
            },
            {
              title: name ?? "",
              className: "text-[24px]",
            },
          ]}
        />
      </Container>

      {!categorySubs || categorySubs.length === 0 ? (
        ""
      ) : (
        <CategoryContainer
          categories={categorySubs ?? []}
          loading={loading}
          navigateUrl="/category-sub"
        />
      )}

      <Container>
        <ProductGrid loading={loading} products={categoryProducts} />
        <></>
      </Container>
    </>
  );
};

export default Category;
