import { INotification } from "../../../../context.tsx/user/context";

interface IProps {
  notification: INotification;
}

const NotificationItem = ({ notification }: IProps) => {
  return (
    <div className="flex flex-col py-[40px] px-[30px] bg-[#EFEFEF]">
      <div className="flex justify-between">
        <div className="flex flex-col gap-[20px]">
          <div className="flex gap-[30px]">
            <div className="text-[20px] font-bold">اشعار من افاق :</div>
            <div dir="ltr" className="text-[20px] font-bold">
              {notification.message}
            </div>
            {/* <div className="text-[20px] font-bold">تحت المراجعة</div> */}
          </div>
          <div>{new Date(notification.created_at).toLocaleDateString()}</div>
        </div>
        <div>
          {notification.product && (
            <img
              className="w-[80px] h-[80px]"
              src={notification?.product?.cover[0]}
              alt=""
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default NotificationItem;
