import { Fragment } from "react";
import ProductCard from "../product-card/ProductCard";
import { IProduct } from "../../../models/product";
import ProductSkeleton from "../product-skeleton/ProductSkeleton";
export interface IProps {
  products: IProduct[];
  loading: boolean;
}
const ProductGrid = ({ products, loading }: IProps) => {
  const skeletons = [1, 2, 3, 4];
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-[78px] mt-[40px]">
      {loading ? (
        <>
          {skeletons?.map((skeleton) => (
            <Fragment key={skeleton}>
              <ProductSkeleton />
            </Fragment>
          ))}
        </>
      ) : (
        <>
          {products?.map((product) => {
            return (
              <Fragment key={product.id}>
                <ProductCard product={product} />
              </Fragment>
            );
          })}
        </>
      )}
    </div>
  );
};

export default ProductGrid;
