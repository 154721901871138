import { ErrorMessage } from "@hookform/error-message";
import { Breadcrumb, Button, Input, notification } from "antd";
import { Controller, useForm } from "react-hook-form";
import FormItem from "../../../general/form-item/FormItem";
import http from "../../../../api/api";
import { useState } from "react";

interface IProps {
  onClickSettings: () => void;
}
const UserPassword = ({ onClickSettings }: IProps) => {
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  const [loading, setLoading] = useState(false);

  const onSubmit = async (data: any) => {
    try {
      setLoading(true);
      await http.post("/auth/change-password", {
        newPassword: data.newPassword,
        oldPassword: data.oldPassword,
      });
      onClickSettings();
    } catch (err) {
      notification.error({
        message: "بيانات الاعتماد غير صالحة",
        placement: "bottomRight",
        style: { direction: "rtl" },
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Breadcrumb
        separator={<div className="text-[24px]">{`>`}</div>}
        items={[
          {
            title: (
              <div
                onClick={() => onClickSettings()}
                className="text-[24px] hover:bg-gray-300 transition cursor-pointer rounded-md hover:h-auto"
              >
                الاعدادات
              </div>
            ),
          },
          {
            title: "تعديل كلمة المرور",
            className: "text-[24px]",
          },
        ]}
      />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="mt-4 flex flex-col gap-4"
      >
        <FormItem label=" كلمة المرور القديمة">
          <Controller
            control={control}
            rules={{
              required: { value: true, message: "كلمة المرور القديمة مطلوبة" },
            }}
            name="oldPassword"
            render={({ field }) => {
              return (
                <Input.Password
                  {...field}
                  className="h-[70px] rounded-none text-[15px] font-black"
                  placeholder="كلمة المرور القديمة"
                />
              );
            }}
          />
          <ErrorMessage
            className="mt-4 text-red-600"
            as={"div"}
            errors={errors}
            name="oldPassword"
          />
        </FormItem>

        <FormItem label=" كلمة المرور الجديدة">
          <Controller
            control={control}
            rules={{
              required: { value: true, message: "كلمة المرور الجديدة مطلوبة" },
            }}
            name="newPassword"
            render={({ field }) => {
              return (
                <Input.Password
                  {...field}
                  className="h-[70px] rounded-none text-[15px] font-black"
                  placeholder="كلمة المرور الجديدة"
                />
              );
            }}
          />
          <ErrorMessage
            className="mt-4 text-red-600"
            as={"div"}
            errors={errors}
            name="newPassword"
          />
        </FormItem>
        <FormItem label="تأكيد كلمة المرور">
          <Controller
            control={control}
            rules={{
              required: { value: true, message: "تأكيد كلمة المرور مطلوبة" },
              validate: (value) => {
                return (
                  value === getValues("newPassword") ||
                  `كلمتا المرور غير متطابقتان`
                );
              },
            }}
            name="confirmPassword"
            render={({ field }) => {
              return (
                <Input.Password
                  {...field}
                  className="h-[70px] rounded-none text-[15px] font-black"
                  placeholder="تأكيد كلمة المرور"
                />
              );
            }}
          />
          <ErrorMessage
            className="mt-4 text-red-600"
            as={"div"}
            errors={errors}
            name="confirmPassword"
          />
        </FormItem>

        <div className="w-full flex justify-center">
          <Button
            className="w-1/2  text-[20px] font-black h-[74px]"
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            حفظ التغييرات
          </Button>
        </div>
      </form>
    </>
  );
};

export default UserPassword;
