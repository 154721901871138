import { createContext } from "react";
import { IProduct } from "../../models/product";
import { ILogin, ISignUp, IUSer } from "../../models/user";
import { ACCESS_TOKEN } from "../../api/api";

export type UserLoading =
  | "login"
  | "logout"
  | "change_password"
  | "forget_password"
  | "register"
  | "create_order"
  | "get_user_favourites";

export interface ICart extends IProduct {
  quantity: number;
}

export interface INotification {
  id: number;
  message: string;
  type: "offer";
  product: IProduct;
  created_at: string;
  updated_at: string;
}

export interface IInternalState {
  user?: IUSer;
  userFavourites: IProduct[];
  loading: UserLoading[];
  isAuthenticated: boolean;
  cart: ICart[];
  notifications: INotification[];
}

export const internatState: IInternalState = {
  loading: [],
  cart: [],
  userFavourites: [],
  isAuthenticated: !localStorage.getItem(ACCESS_TOKEN) ? false : true,
  notifications: [],
};

export interface IExternalState extends IInternalState {
  actions: {
    login: (req: ILogin) => void;
    logout: () => void;
    register: (req: ISignUp) => void;
    forgetPassword: (email: string) => void;
    setUser: (data: any) => void;
    addToCart: (quantity: number, product: IProduct) => void;
    updateUserFavourites: (product: IProduct) => void;
    getUserFavourites: () => void;
    removeFromCart: (id: number) => void;
    createOrder: (data: any, note?: string, code?: string) => void;
    me: () => void;
  };
}

export const externalState: IExternalState = {
  ...internatState,
  actions: {
    login: () => null,
    logout: () => null,
    register: () => null,
    forgetPassword: () => null,
    setUser: () => null,
    addToCart: () => null,
    updateUserFavourites: () => null,
    getUserFavourites: () => null,
    removeFromCart: () => null,
    me: () => null,
    createOrder: () => null,
  },
};

const UserContext = createContext(externalState);

export default UserContext;
