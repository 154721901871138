import { Layout } from "antd";
import React from "react";

const { Footer: LayoutFooter } = Layout;
const Footer = () => {
  return (
    <LayoutFooter
      className="mt-[150px]"
      style={{ color: "white", textAlign: "center" }}
      color="white"
    >
      <div style={{ fontSize: "20px", fontWeight: "bold" }}>
        حقوق الطبع والنشر © 2023 بوجنبول. جميع الحقوق محفوظة هذا الموقع محمي
        بواسطة وتطبق سياسة الخصوصية وشروط الخدمة الخاصة بشركة
      </div>
    </LayoutFooter>
  );
};

export default Footer;
