interface Props {
  name: string;
  url: string;
  navigteUrl: () => void;
}
const Category = ({ name, url, navigteUrl }: Props) => {
  return (
    <div
      onClick={() => navigteUrl()}
      className="w-[150px] h-[150px] md:w-[210px] md:h-[210px] shrink-0  shadow-md hover:shadow-lg rounded-full cursor-pointer relative transition ease-in-out duration-200"
    >
      <img
        className="w-full h-full object-cover rounded-full"
        src={url}
        alt=""
      />
      <div className="absolute bottom-0 flex w-full justify-center rounded-full text-[18px]  md:text-[24px] font-black  text-white mb-[40px]">
        <span className="bg-gray-500/60 py-[1px] px-[2px] rounded-md">
          {name}
        </span>
      </div>
    </div>
  );
};

export default Category;
