import { useState } from "react";
import UserForm from "./user-form/UserForm";
import UserPassword from "./user-password/UserPassword";

const UserSettings = () => {
  const [changePassword, setChangePassword] = useState(false);

  return (
    <>
      {changePassword ? (
        <UserPassword onClickSettings={() => setChangePassword(false)} />
      ) : (
        <UserForm
          onClickChangePassword={() => {
            setChangePassword(true);
          }}
        />
      )}
    </>
  );
};

export default UserSettings;
