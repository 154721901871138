import { Fragment, useEffect, useState } from "react";
import http from "../../../api/api";
import OrderItemDetails from "./OrderItemDetails.tsx/OrderItemDetails";

const UserOrders = () => {
  const [orders, setOrders] = useState<any>();

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await http.get("/orders");
        setOrders(data.data);
      } catch (err) {
      } finally {
      }
    };

    getData();
  }, []);
  return (
    <div className="mt-4 flex flex-col gap-4">
      {orders?.map((item: any, index: number) => {
        return (
          <Fragment key={index}>
            <OrderItemDetails order={item} />
          </Fragment>
        );
      })}
    </div>
  );
};

export default UserOrders;
