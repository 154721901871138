import { Fragment, useContext } from "react";
import NotificationItem from "./notification-item/NotificationItem";
import UserContext from "../../../context.tsx/user/context";

const UserNotifications = () => {
  const { notifications } = useContext(UserContext);
  return (
    <div className="mt-4 flex flex-col gap-[30px]">
      {notifications?.map((item) => {
        return (
          <Fragment key={item.id}>
            <NotificationItem notification={item} />
          </Fragment>
        );
      })}
    </div>
  );
};

export default UserNotifications;
