import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { InboxOutlined } from "@ant-design/icons";
import { Button, Input, Upload, UploadFile } from "antd";
import { useContext, useEffect, useState } from "react";
import FormItem from "../../../general/form-item/FormItem";
import UserContext from "../../../../context.tsx/user/context";
import http from "../../../../api/api";
const { Dragger } = Upload;

interface IProps {
  onClickChangePassword: () => void;
}
const UserForm = ({ onClickChangePassword }: IProps) => {
  const { user, actions } = useContext(UserContext);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: { ...user, photo: undefined },
  });
  const onSubmit = async (data: any) => {
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      if (Array.isArray(data[key])) {
        data[key]?.forEach((item: any) => {
          formData.append(key, item);
        });
      } else {
        formData.append(key, data[key] ?? "");
      }
    });

    try {
      setLoading(true);
      await http.post("/auth/me", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      await actions.me();
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    reset({ ...user, photo: undefined });
  }, [user, reset]);

  const [fileListLogo, setFileListLogo] = useState<UploadFile[]>([]);

  const [inputValue, setInputValue] = useState("-------");

  const handleInputChange = (event: any) => {
    setInputValue(event.target.value);
  };

  const renderMaskedValue = () => {
    return inputValue.replace(/./g, "●"); // Replace each character with a black circle
  };

  const [loading, setLoading] = useState(false);
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="mt-4 flex flex-col gap-4"
    >
      <FormItem label="الاسم الكامل">
        <Controller
          control={control}
          rules={{
            required: { value: true, message: "الاسم الكامل مطلوب" },
          }}
          name="name"
          render={({ field }) => {
            return (
              <Input
                {...field}
                placeholder="الاسم الكامل"
                className="h-[50px] rounded-none text-[15px] font-black"
              />
            );
          }}
        />
        <ErrorMessage
          className="mt-4 text-red-600"
          as={"div"}
          errors={errors}
          name="name"
        />
      </FormItem>
      {/* phone number */}
      <FormItem label="رقم الهاتف">
        <Controller
          control={control}
          rules={{
            required: { value: true, message: "رقم الهاتف مطلوب" },
          }}
          name="phone"
          render={({ field }) => {
            return (
              <Input
                {...field}
                placeholder="رقم الهاتف"
                className="h-[50px] rounded-none text-[15px] font-black"
              />
            );
          }}
        />
        <ErrorMessage
          className="mt-4 text-red-600"
          as={"div"}
          errors={errors}
          name="phone"
        />
      </FormItem>
      {/* email */}
      {/* <FormItem label="البريد الالكتروني">
    <Controller
      control={control}
      rules={{
        required: { value: true, message: "البريد الالكتروني مطلوب" },
      }}
      name="email"
      render={({ field }) => {
        return (
          <Input
            {...field}
            placeholder="البريد الالكتروني"
            className="h-[50px] rounded-none text-[15px] font-black"
          />
        );
      }}
    />
    <ErrorMessage
      className="mt-4 text-red-600"
      as={"div"}
      errors={errors}
      name="email"
    />
  </FormItem> */}
      {/* password */}
      <FormItem label="كلمة المرور">
        <Input
          security="8"
          suffix={
            <div
              onClick={() => {
                onClickChangePassword();
              }}
              className="text-[10px] cursor-pointer underline hover:text-[#F36B10]"
            >
              تعديل كلمة المرور
            </div>
          }
          id="maskedInput"
          value={renderMaskedValue()}
          onChange={handleInputChange}
          placeholder="كلمة المرور"
          className="h-[50px] rounded-none text-[15px] font-black"
        />
      </FormItem>

      {/* governate */}
      <FormItem label="المحافظة">
        <Controller
          control={control}
          rules={{
            required: { value: true, message: "المحافظة مطلوب" },
          }}
          name="country"
          render={({ field }) => {
            return (
              <Input
                {...field}
                placeholder="المحافظة"
                className="h-[50px] rounded-none text-[15px] font-black"
              />
            );
          }}
        />
        <ErrorMessage
          className="mt-4 text-red-600"
          as={"div"}
          errors={errors}
          name="country"
        />
      </FormItem>

      {/* city */}
      <FormItem label="المنطقة">
        <Controller
          control={control}
          rules={{
            required: { value: true, message: "المنطقة مطلوب" },
          }}
          name="city"
          render={({ field }) => {
            return (
              <Input
                {...field}
                placeholder="المنطقة"
                className="h-[50px] rounded-none text-[15px] font-black"
              />
            );
          }}
        />
        <ErrorMessage
          className="mt-4 text-red-600"
          as={"div"}
          errors={errors}
          name="city"
        />
      </FormItem>

      {/* home address */}
      <FormItem label="عنوان المنزل">
        <Controller
          control={control}
          rules={{
            required: { value: true, message: "عنوان المنزل مطلوب" },
          }}
          name="address"
          render={({ field }) => {
            return (
              <Input
                {...field}
                placeholder="عنوان المنزل"
                className="h-[50px] rounded-none text-[15px] font-black"
              />
            );
          }}
        />
        <ErrorMessage
          className="mt-4 text-red-600"
          as={"div"}
          errors={errors}
          name="address"
        />
      </FormItem>

      {/* Image */}
      <FormItem label="الصورة الشخصية">
        <Controller
          control={control}
          rules={{
            required: { value: true, message: "الصورة الشخصية مطلوب" },
          }}
          name="photo"
          render={({ field }) => {
            return (
              <Dragger
                multiple={false}
                fileList={fileListLogo}
                beforeUpload={(file: any) => {
                  setFileListLogo([file]);
                  field.onChange(file);

                  /* update state here */
                  return false;
                }}
                onRemove={() => {
                  setFileListLogo([]);
                  field.onChange(undefined);
                }}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
              </Dragger>
            );
          }}
        />
        <ErrorMessage
          className="mt-4 text-red-600"
          as={"div"}
          errors={errors}
          name="photo"
        />
      </FormItem>

      <div className="w-full flex justify-center">
        <Button
          loading={loading}
          className="w-1/2  text-[20px] font-black h-[74px]"
          type="primary"
          htmlType="submit"
        >
          حفظ التغييرات
        </Button>
      </div>
    </form>
  );
};

export default UserForm;
