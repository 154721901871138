import React, { Fragment } from "react";
import OrderItem from "../OrderITem/OrderItem";

interface IProps {
  order: any;
}
const OrderItemDetails = ({ order }: IProps) => {
  return (
    <div className="flex flex-col md:grid md:grid-cols-4 gap-4 px-[27px] py-[37px] bg-[#EFEFEF] items-center">
      {/* <OrderItem orderItem={order?.details[0]} /> */}
      {order.details.map((item: any, index: number) => {
        return (
          <Fragment key={index}>
            <OrderItem
              status={index === 0 ? order.status : ""}
              orderItem={item}
            />
          </Fragment>
        );
      })}
    </div>
  );
};

export default OrderItemDetails;
